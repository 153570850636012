import {Badge, CardMedia, Container, Grid, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import useProduct from "../../store/useProduct";
import RichText from "../RichText";

export default function ProductMain() {
  const { id } = useParams();
  const product = useProduct(Number(id));
  const [firstPhoto, ...restOfPhotos] = product ? product.photos : [];

  return <Container component="main">
    {product &&
    <>
      <Typography variant="h1" component="h1">
        {product.title} <Badge>{product.price}</Badge>
      </Typography>
      {firstPhoto &&
        <CardMedia
          component="img"
          image={firstPhoto.url}
          alt={firstPhoto.name}
        />
      }
      <Typography variant="body1" component="h6">
        <RichText>{product.description}</RichText>
      </Typography>
      {!!restOfPhotos.length &&
      <Grid container spacing={1}>
        {restOfPhotos.map(photo =>
          <Grid item xs={12}>
            <CardMedia
              component="img"
              image={photo.url}
              alt={photo.name}
            />
          </Grid>
        )}
      </Grid>
      }
    </>
    }
  </Container>
}

export function getApiUrl(): string {
  return process.env.REACT_APP_API_URL || "";
}

export function isDevelopment() {
  return process.env.NODE_ENV === "development";
}

export function isProduction() {
  return !isDevelopment();
}

import React from "react";

interface SygnetProps {
  width?: string | number;
  height?: string | number;
}

export default function Sygnet(props: SygnetProps) {
  const {
    width = "100%",
    height = "100%",
  } = props;

  const innerWidth = 1133.8586;
  const innerHeight = 1222.224;

  return <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="svg2"
    xmlSpace="preserve"
    width={width}
    height={height}
    viewBox={`0 0 ${innerWidth} ${innerHeight}`}>
    <defs
      id="defs6">
      <clipPath
        clipPathUnits="userSpaceOnUse"
        id="clipPath18">
        <path
          d="M 0,916.668 H 850.394 V 0 H 0 Z"
          id="path16"/>
      </clipPath>
    </defs>
    <g
      id="g10"
      transform="matrix(1.3333333,0,0,-1.3333333,0,1222.224)">
      <g
        id="g12">
        <g
          id="g14"
          clipPath="url(#clipPath18)">
          <g
            id="g20"
            transform="translate(535.314,409.2954)">
            <path
              d="m 0,0 c 0.281,34.471 -34.541,47.038 -82.771,64.448 l -11.866,4.283 c -35.453,12.707 -74.908,26.889 -86.703,59.464 -4.633,-4.563 -8.846,-9.618 -12.778,-15.094 -20.71,-29.416 -28.082,-69.714 -20.218,-110.573 14.883,-77.437 75.681,-125.949 144.342,-115.348 14.673,2.247 28.714,7.231 41.701,14.744 l 5.266,-5.547 c -22.255,-12.426 -47.319,-18.955 -72.803,-18.955 -82.842,0 -152.767,66.414 -152.767,145.044 0,47.599 25.696,90.705 64.379,117.313 -0.492,3.019 -0.702,6.178 -0.702,9.477 0,28.785 31.522,71.399 99.06,71.399 44.72,0 79.682,-15.726 101.657,-45.634 L 0,169.124 c -20.991,30.329 -51.039,46.968 -85.019,46.968 -34.47,0 -52.162,-13.198 -61.007,-24.291 -9.478,-12.005 -12.708,-26.748 -11.865,-37.349 0.14,-1.756 0.35,-3.44 0.702,-4.914 0.21,-1.054 0.491,-2.107 0.912,-3.09 7.653,-25.906 33.839,-35.173 72.733,-48.863 5.125,-1.755 10.53,-3.65 16.077,-5.686 41.14,-14.884 95.83,-40.158 95.83,-87.546 0,-51.881 -55.041,-70.416 -106.571,-70.416 -53.848,0 -89.863,21.483 -101.938,60.657 l 17.551,3.51 c 7.652,-27.099 30.118,-57.006 91.828,-57.006 C -30.68,-58.902 -0.211,-33.558 0,0"
              fill="#1d1d1b"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              id="path22"/>
          </g>
          <g
            id="g24"
            transform="translate(518.4009,548.5967)">
            <path
              d="m 0,0 c 0,-6.417 -5.202,-11.619 -11.619,-11.619 -6.417,0 -11.618,5.202 -11.618,11.619 0,6.417 5.201,11.619 11.618,11.619 C -5.202,11.619 0,6.417 0,0"
              fill="#1d1d1b"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              id="path26"/>
          </g>
        </g>
      </g>
    </g>
  </svg>;
}

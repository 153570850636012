import {Route, Routes} from "react-router-dom";
import Home from "./Home";
import Page from "./Page";
import ProductPage from "./ProductPage";

export default function Shop() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/product/:id" element={<ProductPage />} />
      <Route path="/:slug" element={<Page />} />
    </Routes>
  )
}

import {Container} from "@mui/material";
import {useParams} from "react-router-dom";
import RichText from "../RichText";
import usePage from "../../store/usePage";

interface Page {
  title: string;
  content: string;
}

export default function PageMain() {
  const { slug } = useParams();
  const page = usePage(slug as string);

  return <Container component="main">
    {!!page && <RichText>{page.content}</RichText>}
  </Container>
}

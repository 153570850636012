import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React, {useMemo} from 'react';
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
// import Teaser from "./Teaser";
// import useFeatureFlag from "../hooks/useFeatureFlag";
import Shop from "./Shop";

function App() {
  const theme = useMemo(() => createTheme(),[])
  // const isShopMode = useFeatureFlag("shop_mode");

  return <React.Fragment>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {/*{isShopMode ? <Shop /> : <Teaser />}*/}
        <Shop />
      </BrowserRouter>
    </ThemeProvider>
  </React.Fragment>
}

export default App;

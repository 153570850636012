import React from "react";
import {Container} from "@mui/material";

interface VerticalLayoutProps {
  children: React.ReactNode;
}

export default function VerticalLayout(props: VerticalLayoutProps) {
  return <Container sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
    {props.children}
  </Container>
}

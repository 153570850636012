import React from "react";
import {Box} from "@mui/material";

interface RichTextProps {
  children: string;
}

export default function RichText(props: RichTextProps) {
  return <Box dangerouslySetInnerHTML={{ __html: props.children }} />
}

import {Link} from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import useSocialLinks from "../store/useSocialLinks";

export default function SocialLinks() {
  const socialLinks = useSocialLinks();

  return <>
    {socialLinks.map(link =>
      <Link key={link.type} target="_blank" href={link.url}>
        {link.type === "facebook" && <FacebookIcon />}
        {link.type === "instagram" && <InstagramIcon />}
      </Link>
    )}
  </>
}

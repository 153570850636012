import useProducts from "../store/useProducts";
import ProductCard from "./ProductCard";
import {Grid} from "@mui/material";

export default function HomeProducts() {
  const products = useProducts();

  return (<Grid container spacing={6}>
    {products.map(product =>
      <Grid item key={product.id} xs={12} md={6}>
        <ProductCard product={product} />
      </Grid>
    )}
  </Grid>);
}

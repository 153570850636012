import Header from "./Header";
import Footer from "./Footer";
import PageMain from "./main/PageMain";
import VerticalLayout from "./VerticalLayout";
import ProductMain from "./main/ProductMain";

export default function ProductPage() {
  return (
    <VerticalLayout>
      <Header />
      <ProductMain />
      <Footer />
    </VerticalLayout>
  );
}

interface SocialLink {
  type: "facebook" | "instagram";
  url: string;
}

export default function useSocialLinks(): SocialLink[] {
  return [
    {
      type: "facebook",
      url: "https://www.facebook.com/Sadowska_collection-103591652420086"
    },
    {
      type: "instagram",
      url: "https://www.instagram.com/sadowska_collection/"
    },
  ];
}

import useApi from "../hooks/useApi";

export interface Product {
  id: number;
  title: string;
  description: string;
  price: string;
  photos: File[];
}

interface File {
  name: string;
  url: string;
}

export default function useProducts(): Product[] {
  const { data } = useApi<Product[]>("/products");

  return data || [];
}

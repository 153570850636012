import * as React from 'react';
import usePages, { Page } from "../store/usePages";
import {Box, Drawer, Link} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Link as RouterLink} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Sygnet from "./Sygnet";
import Button from "@mui/material/Button";

interface AppBarProps {
  pages: Pick<Page, "slug" | "title">[];
  anchorElNav: null | HTMLElement;
  handleOpenNavMenu(event: React.MouseEvent<HTMLElement>): void;
  handleCloseNavMenu(): void;
  onCloseNavMenu(): void;
}

export default function HeaderAppBar() {
  const pages = usePages();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    // <Container maxWidth={false}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <AppBarSx pages={pages} anchorElNav={anchorElNav} handleCloseNavMenu={handleCloseNavMenu} handleOpenNavMenu={handleOpenNavMenu} />
        <AppBarMd pages={pages} onCloseNavMenu={handleCloseNavMenu} />
      </Box>
    // </Container>
  );
}

function AppBarSx(props: Omit<AppBarProps, "onCloseNavMenu">) {
  const { pages, anchorElNav, handleOpenNavMenu, handleCloseNavMenu } = props;

  return (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="left"
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          >
          {pages.map((page) => (
            <MenuItem key={page.slug} onClick={handleCloseNavMenu}>
              <Link component={RouterLink} to={`/${page.slug}`}>
                <Typography textAlign="center">{page.title}</Typography>
              </Link>
            </MenuItem>
          ))}
        </Drawer>
      </Box>
      <Typography
        variant="h5"
        noWrap
        component={RouterLink}
        to="/"
        sx={{
          mr: 2,
          display: { xs: 'flex', md: 'none' },
          flexGrow: 1,
        }}
      >
        <Sygnet width="4em" height="4em" />
      </Typography>
    </>
  );
}

function AppBarMd(props: Pick<AppBarProps, "pages" | "onCloseNavMenu">) {
  const { pages, onCloseNavMenu } = props;
  const display = { xs: 'none', md: 'flex' };

  return (
    <>
      <Link
        component={RouterLink}
        to="/"
        sx={{
          mr: 2,
          display,
        }}
      >
        <Sygnet width="6em" height="6em" />
      </Link>
      <Box sx={{ flexGrow: 1, display }}>
        {pages.map((page) => (
          <Link
            component={RouterLink}
            key={page.slug}
            to={`/${page.slug}`}
          >
            <Button
              key={page.slug}
              onClick={onCloseNavMenu}
              sx={{ my: 2, display: 'block' }}
            >
              {page.title}
            </Button>
          </Link>
        ))}
      </Box>
    </>
  );
}

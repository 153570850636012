import useApi from "../hooks/useApi";

export interface Page {
  slug: string;
  title: string;
  content: string;
}

export default function usePages(): Page[] {
  const { data } = useApi<Page[]>("/pages");

  return data || [];
}

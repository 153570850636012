import {Box, Container, Divider, Grid} from "@mui/material";
import Sygnet from "./Sygnet";
import SocialLinks from "./SocialLinks";
import Copyright from "./Copyright";

export default function Footer() {
  return <Container sx={{ mt: 'auto' }}  component="footer">
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Sygnet width="3em" height="3em" />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ height: "1px" }} variant="fullWidth" />
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Copyright />
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <SocialLinks />
        </Grid>
      </Grid>
    </Box>
  </Container>
}

import Header from "./Header";
import Footer from "./Footer";
import HomeMain from "./main/HomeMain";
import VerticalLayout from "./VerticalLayout";

export default function Page() {
  return (
    <VerticalLayout>
      <Header />
      <HomeMain />
      <Footer />
    </VerticalLayout>
  );
}

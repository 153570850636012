import { useFetch as useFetchFromHooksTs } from "usehooks-ts";

type State<T> = {
  data?: T
  error?: Error;
  isLoading: boolean;
}

export default function useFetch<T>(url: string, init?: RequestInit): State<T> {
  const { data, error } = useFetchFromHooksTs<T>(url, init);

  return { data, error, isLoading: !data && !error };
}
